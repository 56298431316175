import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Container,
  Heading
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons'

const FAQ = ({ faq, title }) => {
  return (
    <Box as='section' bg='bg-surface'>
      <Container
        py={{
          base: '16',
          md: '28'
        }}
      >
        <Heading
          as='h1'
          fontWeight='bold'
          letterSpacing='tight'
          fontSize='32px'
          textAlign='center'
          mb='16'
        >
          {title}
        </Heading>
        <Accordion allowMultiple>
          {faq?.map(data => (
            <AccordionItem key={data.question}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton className='faq'>
                      <Box flex='1' textAlign='left' fontWeight='500'>
                        {data.question}
                      </Box>
                      <FontAwesomeIcon icon={isExpanded ? faMinusCircle : faPlusCircle} color='#193154' />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} maxW='90%'>
                    {data.answer.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </Box>
  )
}

export default FAQ
