import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Pricing from '../components/pricing'
import PricingFaq from '../components/PricingFAQ'

const PricingPlan = ({ data }) => {
  const contentfulPricingPage = data.contentfulPricingPage
  const pricingFaq = data.contentfulPricingPage.pricingFaq

  return (
    <Layout>
      <Pricing contentfulPricingPage={contentfulPricingPage} />
      <PricingFaq faq={pricingFaq.faq} title={pricingFaq.pricingFaqTitle} />
    </Layout>
  )
}

export default PricingPlan

export const query = graphql`
  query {
    contentfulPricingPage {
      pageTitle
      pricingCard {
        title
        amount
        markAsPopular
        responsesText
        monthly
        noCardText
        getStartedButton
        tryForFreeButton {
          text
        }
        listItem {
          itemDescription
          putStrike
        }
      }
      pricingFaq {
        pricingFaqTitle
        faq {
          question
          answer {
            answer
          }
        }
      }
    }
  }
`
