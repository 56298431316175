import React, { useState } from 'react'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tag,
  Text,
  VStack,
  Heading,
  useBreakpointValue
} from '@chakra-ui/react'
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

import DateTypeSelect from './pricing/DateTypeSelect'

const CUSTOM_PRICING_LINK = 'mailto:sales@facefile.co?subject=Subscription'
const APP_LINK = 'https://app.facefile.co'
const PRICES = {
  month: [
    {
      amount: '50',
      price: '$39/mo',
      mostPopular: true
    },
    {
      amount: '150',
      price: '$99/mo'
    },
    {
      amount: '300',
      price: '$150/mo'
    },
    {
      amount: '300+',
      price: 'Custom pricing'
    }
  ],
  yearly: [
    {
      amount: '50',
      price: '$30/mo'
    },
    {
      amount: '150',
      price: '$75/mo'
    },
    {
      amount: '300',
      price: '$100/mo'
    },
    {
      amount: '300+',
      price: 'Custom pricing'
    }
  ]
}

const PricingComponent = ({ contentfulPricingPage }) => {
  const [isYearType, setIsYearType] = useState(false)
  const pricesList = isYearType ? PRICES.yearly : PRICES.month
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true
  })

  const FeatureRow = ({ active, children }) => (
    <HStack>
      <Center
        rounded='full'
        bg={active ? 'teal.100' : 'gray.200'}
        boxSize={4}
        color={active ? 'blue.700' : 'gray.400'}
      >
        <FontAwesomeIcon icon={active ? faCheck : faTimes} fontSize='0.5em' />
      </Center>
      <Text
        color={active ? 'blue.800' : 'gray.400'}
        fontWeight='normal'
        sx={{
          textDecorationLine: active ? 'none' : 'line-through'
        }}
      >
        {children}
      </Text>
    </HStack>
  )

  const PriceRow = ({ value, description, active }) => (
    <Center w='full'>
      <VStack>
        <Text color='teal.400' fontWeight={700} fontSize='4xl'>
          {active ? value : '$0'}
        </Text>
        <Text color='teal.400'>
          {active ? description : '20 responses per month'}
        </Text>
      </VStack>
    </Center>
  )

  const Card = ({ active, children }) => (
    <VStack
      w='full'
      borderWidth='medium'
      borderStyle='solid'
      borderColor={active ? 'teal.400' : 'transparent'}
      borderRadius='xl'
      backgroundColor='white'
      boxShadow='md'
      px='8'
      py='12'
      align='start'
      spacing='6'
    >
      {children}
    </VStack>
  )

  const PlanSelect = ({ active, title }) => {
    const [selectedPlanId, setSelectedPlan] = useState(0)
    const renderSlider = () => {
      return (
        <Box w='full' flexShrink={0} h='14' pt={2} px={3}>
          <Slider
            value={selectedPlanId}
            onChange={val => setSelectedPlan(val)}
            min={0}
            max={_.size(pricesList) - 1}
            colorScheme='teal'
          >
            <SliderTrack h={1.5} bg='gray.200'>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb w={5} h={5} borderWidth={2} borderColor='teal.400' />
            {_.map(pricesList, (p, i) => {
              return (
                <SliderMark
                  value={i}
                  fontSize='xs'
                  key={i}
                  pt={6}
                  position='relative'
                >
                  <Text
                    fontWeight='semibold'
                    color={selectedPlanId >= i ? 'gray.800' : 'gray.500'}
                    textAlign='center'
                    pointerEvents='all'
                    transform='translate(-50%, 0%)'
                    onClick={() => setSelectedPlan(i)}
                  >
                    {p.amount}
                  </Text>
                </SliderMark>
              )
            })}
          </Slider>
        </Box>
      )
    }
    return (
      <VStack spacing='6' w='full' mb='6' h='xs'>
        <Flex direction='row' justify='space-between' w='full'>
          <Text color='gray.800' fontWeight='bold' fontSize='2xl'>
            {title}
          </Text>
          {_.get(pricesList, [selectedPlanId, 'mostPopular'], false) && active && (
            <Box my='auto'>
              <Tag
                backgroundColor='blue.800'
                variant='solid'
                rounded='sm'
                px={2}
                fontWeight='bold'
              >
                Most Popular
              </Tag>
            </Box>
          )}
        </Flex>
        <PriceRow
          value={_.get(pricesList, [selectedPlanId, 'price'])}
          description={`${_.get(pricesList, [
            selectedPlanId,
            'amount'
          ])} responses per month`}
          active={active}
        />
        {active && renderSlider()}
        <VStack w='full' spacing='8'>
          <Button
            as='a'
            href={
              active & (selectedPlanId === 3) ? CUSTOM_PRICING_LINK : APP_LINK
            }
            variant={active ? 'solid' : 'outline'}
            colorScheme='teal'
            backgroundColor={active ? 'teal.500' : 'white'}
            color={active ? 'white' : 'teal.400'}
            w='full'
            borderRadius='md'
            size='lg'
            fontSize='xl'
            fontWeight='semibold'
            rightIcon={
              active && selectedPlanId === 3
                ? (
                  <FontAwesomeIcon icon={faArrowRight} />
                  )
                : null
            }
          >
            {active
              ? selectedPlanId === 3
                ? 'Contact sales'
                : 'Get started'
              : 'Try for free'}
          </Button>
          {!active && (
            <Text color='gray.500' fontWeight='semibold'>
              No credit card required
            </Text>
          )}
        </VStack>
      </VStack>
    )
  }

  const Stack = isDesktop ? HStack : VStack
  return (
    <Box as='section' bg='white' py='10'>
      <Box
        maxW={{
          base: 'xl',
          md: '7xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '32'
        }}
      >
        <VStack spacing='10'>
          <VStack spacing='24'>
            <Heading color='gray.800' fontSize='5xl' fontWeight='bold'>
              {contentfulPricingPage.pageTitle}
            </Heading>
            <DateTypeSelect
              isYearType={isYearType}
              onChange={v => setIsYearType(v)}
            />
          </VStack>
          <Stack justify='space-between' gap='10'>
            {_.map(contentfulPricingPage.pricingCard, (c, i) => (
              <Card active={c.title === 'Pro'} key={i}>
                <PlanSelect
                  active={c.title === 'Pro'}
                  title={c.title}
                  isYearType={isYearType}
                />
                {_.map(c.listItem, (f, index) => (
                  <FeatureRow active={!f.putStrike} key={`disabled_${index}`}>
                    {f.itemDescription}
                  </FeatureRow>
                ))}
              </Card>
            ))}
          </Stack>
        </VStack>
      </Box>
    </Box>
  )
}
export default PricingComponent
